<template>
  <div class="home page">
    <div class="gradient"></div>
        <header>

            <div class="nav-container">
                <router-link to="/"><img src="@/assets/img/logo.png" alt="logo" class="logo"></router-link>
                <Nav/>
            </div>
            <div>
                
            </div>
        </header>
        <section class="content">

            <section>
                <div class="section">
                    <div>
                        <h4 el>Contact HOA Mgmt Company</h4>
                        <p>
                            If you have any questions, comments, or suggestion please contact the First Service Customer Service Center at (703) 385-1133 to speak with one of the customer service representatives. If you need further assistance with an association matter, you will be directed to a management or accounting representative. The office hours will be 9:00 a.m. until 5:30 p.m. EST, Monday through Friday.
                        </p>
                        <!-- <router-link to="/community/request" class="button">Make a Request</router-link> -->
                    </div>
                </div>

            </section>
            <section>

                <div class="section">
                    <div el="list md">
                        <h4 el>Local Services/Emergency</h4>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque laoreet dolor in venenatis blandit. Curabitur et ex nec dui dapibus efficitur vel eu ipsum. Maecenas dignissim velit mollis, accumsan neque eu, vulputate felis. Integer lobortis dapibus justo, et suscipit arcu. Praesent at suscipit justo. Phasellus aliquet efficitur elit, eget vulputate nisl. Nullam non dolor gravida, suscipit dui at, lacinia neque.</p> -->
                        
                        <table el="table">
                            <thead>
                                <tr>
                                    <th>Name</th> 
                                    <th>Contact</th> 
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Emergency Services</td> 
                                    <td>911</td> 
                                </tr> 
                                <tr>
                                    <td><a href="https://www.arcolavfd.org" target="_blank" rel="noopener" el="link">Fire and Rescue</a></td> 
                                    <td>703-327-2222</td> 
                                </tr> 
                                <tr>
                                    <td>Health Department</td> 
                                    <td>703-777-0234</td> 
                                </tr> 
                                <tr>
                                    <td>Animal Care & Control</td> 
                                    <td>703-777-0406</td> 
                                </tr> 
                            </tbody>
                        </table>

                        <h4 el>Healthcare Related Facilities</h4>
                        <table el="table">
                            <thead>
                                <tr>
                                    <th>Name</th> 
                                    <th>Contact</th> 
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="http://www.inova.org/inovapublic.srt/ifoh/index.jsp" target="_blank" rel="noopener" el="link">Inova Fair Oaks Hospital</a></td>
                                    <td>703-391-3600</td>
                                </tr> 
                                <tr>
                                    <td><a href="http://www.loudounhospital.org/" target="_blank" rel="noopener" el="link">Loudoun Hospital</a></td> 
                                    <td>703-858-6000</td> 
                                </tr> 
                                <tr>
                                    <td><a href="http://www.loudounhospital.org/" target="_blank" rel="noopener" el="link">Inova Fairfax Hospital</a></td> 
                                    <td>703-698-1110</td>
                                </tr> 
                                <tr>
                                    <td><a href="http://www.dullesurgentcare.com/" target="_blank" rel="noopener" el="link">Dulles Urgent Care Center</a></td> 
                                    <td>703-542-7921</td>
                                </tr> 
                                <tr>
                                    <td><a href="http://pedsatnite.com/index.html" target="_blank" rel="noopener" el="link">Pediatrics at Nite</a></td> 
                                    <td>571-434-0400</td>
                                </tr> 
                            </tbody>
                        </table>


                        <h4 el>Schools</h4>
                        <table el="table">
                            <thead>
                                <tr>
                                    <th>Name</th> 
                                    <th>Contact</th> 
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href="https://creredwingspto.org/" target="_blank" rel="noopener" el="link">Cardinal Ridge Elementary</a></td>
                                    <td>571-367-4020</td>
                                </tr> 
                                <tr>
                                    <td><a href="https://www.lcps.org/lunsford" target="_blank" rel="noopener" el="link">J. Michael Lunsford Middle</a></td>
                                    <td>703-722-2660</td>
                                </tr> 
                                <tr>
                                    <td><a href="http://www.loudoun.k12.va.us/fhs" target="_blank" rel="noopener" el="link">Freedom High School</a></td> 
                                    <td>703-957-4300</td>
                                </tr> 
                                <!-- END -->
                                <tr>
                                    <td><a href="http://www.loudoun.k12.va.us/pinebrook" target="_blank" rel="noopener" el="link">Pinebrook Elementary</a></td>
                                    <td>703-957-4325</td>
                                </tr> 
                                <tr>
                                    <td><a href="http://www.loudoun.k12.va.us/mercer" target="_blank" rel="noopener" el="link">Mercer Middle School</a></td> 
                                    <td>703-957-4340</td> 
                                </tr> 
                                <tr>
                                    <td><a href="http://www.loudoun.k12.va.us/fhs" target="_blank" rel="noopener" el="link">John Champe High School</a></td> 
                                    <td>703-722-2680</td>
                                </tr> 
                            </tbody>
                        </table>


                        <h4 el>Utilities</h4>
                        <table el="table">
                            <thead>
                                <tr>
                                    <th>Name</th> 
                                    <th>Contact</th> 
                                </tr> 
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Comcast Cable</td>
                                    <td>800-266-2278</td>
                                </tr> 
                                <tr>
                                    <td>Novec Electricity</td>
                                    <td>703-335-0500</td>
                                </tr> 
                                <tr>
                                    <td>Dominion Power</td>
                                    <td>888-667-3000</td>
                                </tr>
                                <tr>
                                    <td>Washington Gas</td>
                                    <td>703-750-1000</td>
                                </tr>
                                <tr>
                                    <td>Verizon</td>
                                    <td>800-826-2355</td>
                                </tr>
                                <tr>
                                    <td>LC Sanitation Authority</td>
                                    <td>703-771-1092</td>
                                </tr>
                                <tr>
                                    <td>LC Erosion Control/Draining</td>
                                    <td>703-737-8746</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

        </section>
        <Footer />
  </div>

</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Contact',
  components: {
      Nav,
      Footer
  },
  data(){
    return {
      slideshowData: [{
          src: require("@/assets/img/club_house.jpg"),
          type: 'img',
          text: 'Contact Us',
      }],
      
    }
  }
}
</script>
<style>
header{
    min-height: 280px;
    background-color: rgba(0, 0, 0, 0.6);
}
@media(max-width:600px){
    td{
        font-size:14px;
    }
}
</style>

